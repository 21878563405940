import React from 'react';
import {useOutlet} from 'reconnect.js';
import * as Generic from '../../Generic';
import {navigate} from 'gatsby';
import {Uploader} from '../../Components/Uploader';
import styled from 'styled-components';
import {message, Button} from 'antd';
import copy from 'copy-to-clipboard';

function AdminMedia(props) {
  const [actions] = useOutlet('actions');

  const FormSpec = {
    schema: {
      title: '',
      type: 'object',
      required: ['media'],
      properties: {
        media: {type: 'string', title: '素材', default: ''},
      },
    },
    uiSchema: {
      media: {
        'ui:widget': Uploader,
      },
    },
  };

  return (
    <Wrapper>
      <Generic.Form
        schema={FormSpec.schema}
        uiSchema={FormSpec.uiSchema}
        onSubmit={async (formData) => {
          const {media} = formData;
          copy(media);
          message.success('連結已複製');
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
`;

export default AdminMedia;
